import React from 'react';
import './EventModal.css';
import { Link } from 'react-router-dom';
import { doc, updateDoc, increment } from 'firebase/firestore';
import { db } from './firebase';
import axios from 'axios';

const incrementCount = async () => {
  const docRef = doc(db, 'eventCount', 'fromOfficialWebSite');
  try {
    const response = await axios.get('https://ipinfo.io/json');
    //console.log(response);
    if (response.data.ip !== '211.218.53.100') {
      await updateDoc(docRef, {
        count: increment(1),
      });
      //console.log('Count incremented successfully');
    }
  } catch (error) {
    console.error(error);
  }
};

export default function EventModal({ onClose }) {
  return (
    <div className='event-modal'>
      {/* <Link
        className='event-link'
        to='https://blog.naver.com/little_buffett/223716306915'
        onClick={() => incrementCount()}
      > */}
      <Link
        className='event-link'
        to='/event-page'
        onClick={() => incrementCount()}
      >
        <img
          className='event-image'
          src='./images/event-pop-up.png'
          alt='event-pop-up'
        />
      </Link>
      <div className='event-close-group'>
        <button
          className='event-close-for-day'
          onClick={() => {
            localStorage.setItem('hidePopup', new Date().toDateString());
            onClose();
          }}
        >
          오늘하루 띄우지 않기
        </button>
        <button className='event-close-btn' onClick={onClose}>
          닫기
        </button>
      </div>
    </div>
  );
}
